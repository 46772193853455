import { createSelector } from 'reselect';
import exEnv from 'exenv';
import formatUrl from '../../../universal/format-url';

const IS_BROWSER = exEnv.canUseDOM;

export const configSelector = (state) => state.config;

export const configAssetsUrlSelector = createSelector(
  configSelector,
  (config) => {
    const { host, path } = config?.universal?.staticUrls?.assets ?? {};

    return formatUrl(host, path);
  }
);

export const configImagesUrlSelector = createSelector(
  configSelector,
  (config) => {
    const { host, path } = config?.universal?.staticUrls?.images ?? {};

    return formatUrl(host, path);
  }
);

export const configBffBaseUrlSelector = createSelector(
  configSelector,
  (config) => {
    const bffBaseUrl = IS_BROWSER
      ? config?.universal?.bffBaseUrl
      : config?.app?.bffBaseUrl;
    return bffBaseUrl ?? '';
  }
);

export const svcPlaceholderImageUrlSelector = createSelector(
  configImagesUrlSelector,
  (imagesUrl) => `${imagesUrl}/placeholder.png`
);

export const onlinePolicyUrlsSelector = createSelector(
  configSelector,
  (config) => config.onlinePolicyUrls
);

export const communicationPreferencesTopicsSelector = createSelector(
  configSelector,
  (config) => config.universal.communicationPreferences.topics
);

export const orderingEnabledSelector = createSelector(
  configSelector,
  (config) => config?.enableOrderingFeatures
);

export const footerContentSelector = createSelector(
  configSelector,
  orderingEnabledSelector,
  (config, orderingEnabled) => {
    const footer = config?.footer;

    if (footer && !orderingEnabled) {
      const primaryLinkLists = footer?.primaryLinkLists?.filter(
        (primaryLink) => primaryLink?.headingId !== 'MOP'
      );
      return { ...footer, primaryLinkLists };
    }
    return footer;
  }
);

export const giftFaqsUrlSelector = createSelector(
  configSelector,
  (config) => config?.gift?.giftFaqsUrl
);

export const marriottJoinLinkSelector = createSelector(
  configSelector,
  (config) => config?.marriottJoinLink
);

export const storeConfirmationTimeoutSelector = createSelector(
  configSelector,
  (config) => config?.storeConfirmationTimeout
);

export const isMetricSelector = createSelector(configSelector, (config) =>
  Boolean(config.metric)
);
